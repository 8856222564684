/* eslint-disable max-len */
import jwtDecode from 'jwt-decode';
import {URL_TYPES, DEBUGMODE} from '@util/consts.js';
import {useTranslation} from '@hooks/translation-hook.js';
import DOMPurify from 'isomorphic-dompurify';
import reactHtmlParser from 'html-react-parser';


class BachHelpers {
  constructor() {
    this.timeout = false;
    if ( !DEBUGMODE ) {
      window.console.log =
      window.console.warn =
      window.console.error =
      window.console.dir =
      window.console.debug =
      window.console.trace =
      (s) => {
        return true;
      };
    } else if ( process.env.REACT_APP_ENV === 'LOCAL' ||
    process.env.REACT_APP_ENV === 'DEV' ) {
      if ( !document.querySelector( '#debugconsole' ) ) {
        const d = document.createElement( 'div' );
        d.id = 'debugconsole';
        d.innerHTML = '<b class="debugcloser">DEBUGCONSOLE:<b>';
        document.querySelector( 'body' ).appendChild( d );
      }
      window.console.html = (...s) => {
        document.querySelector('#debugconsole').innerHTML += '<div>'+s+'</div>';
      };
      document.querySelector( 'body' )
          .addEventListener( 'click', (e)=>{
            if ( e.target.className == 'debugmodeinfo' ) {
              document.querySelector('#debugconsole').style.display = 'block';
            }
            if ( e.target.className == 'debugcloser' ) {
              document.querySelector('#debugconsole').style.display = 'none';
            }
          });
      console.html( process.env.REACT_APP_ENV );
    }
  }
  get BACHURL() {
    return process.env.REACT_APP_NODESERVER;
  }
  get BACHAPIURL() {
    return process.env.REACT_APP_BPOAPISERVER;
  }

  get TMGAPIURL() {
    return process.env.REACT_APP_TMGAPISERVER;
  }
  /**
   * how many seconds before access token expires should the refresh-token
   * xhr be sent?
   */
  get REFRESHDELAY() {
    return process.env.REACT_APP_TOKENREFRESH * 1;
  }
  get INACTIVITYLOGOUT_MINUTES() {
    return 15;
  }

  ajax(settings) {
    const defaultSettings = {
      bpoapi: false, // set to true to use fastAPI
      tmgapi: false, // set to true to use tmgAPI
      url: '',
      method: 'GET',
      contentType: 'application/x-www-form-urlencoded',
      data: {},
      token: sessionStorage.getItem('bachtoken'),
      abort: null,
    };
    settings = {...defaultSettings, ...settings};
    return new Promise(async (res, rej) => {
      if (settings.url === '') rej(new Error('URL missing'));
      else {
        console.log('sending.data', settings.url, settings.data);
        let data = settings.data;
        if (settings.contentType === 'application/x-www-form-urlencoded') {
          data = new URLSearchParams(data);
        }
        if (settings.method.toUpperCase() === 'GET') {
          // settings.url += '?' + data; <- so geht suche nicht mehr!!!!
          settings.url += Object.keys(data).length !== 0 ? '?' + data : '';
          // console.log(settings.url);
          data = null;
        }
        await new Promise((resolve) =>
          setTimeout(resolve, process.env.REACT_APP_API_SLEEP));


        let url;
        if (settings.bpoapi) url = this.BACHAPIURL;
        else if (settings.tmgapi) url = this.TMGAPIURL;
        else url = this.BACHURL;

        fetch(url + settings.url, {
          method: settings.method,
          credentials: 'include',
          headers: {
            'Content-Type': settings.contentType,
            'Authorization': settings.token ? 'Bearer ' + settings.token : '',
            // ...settings.acceptHeader && {'Accept': settings.acceptHeader},
            // ...settings.responseType && {'Response-Type': settings.responseType},
            // ...settings.responseEncoding && {'Response-Encoding': settings.responseEncoding},
          },
          body: data,
          signal: settings.abort ? settings.abort : null,
        })
            .then((response) => {
              if ( response.status === 401 ) {
                console.warn('no auth - token: ', BA.checkTokenExpired());
                if ( !BA.checkTokenExpired() /* || tokenExp - var was never in use */ ) {
                  window.location.href = process.env.REACT_APP_BASE_URL + '/logoutapp';
                  // console.log( 'redirect to logoutapp' );
                  // throw new Error( '401 Not authorized' );
                } else {
                  /* return {status: 'error',
                    error_status: '401',
                    message: 'Not authorized'};*/
                  throw new Error( '401 Not authorized' );
                }
              } else if ( response.status === 403 ) { // token expired
                // if tokencall response 403, logout
                // else no access message
                if ( !settings.bpoapi && !settings.tmgapi ) {
                  window.location.href = process.env.REACT_APP_BASE_URL + '/logoutexpire';
                } else {
                  return '403';
                }
              } else if ( /* response.status === 400 ||*/ response.status === 422 ) { // komische ErrCodes
                // throw new Error( 'XHR Error 400' );
                return Promise.reject(response);
              } else if (response.status < 200 || response.status > 299) {
                const err = new Error(response.status);
                err.response = response;
                return response.headers.get('content-type') &&
                  response.headers.get('content-type')
                      .indexOf('application/json') === 0 ?
                  err.response.json() : err.response.text();
              }

              return response.headers.get('content-type') &&
                     response.headers.get('content-type')
                         .indexOf('application/json') === 0 ?
                     response.json() : response.headers.get('content-type')
                         .indexOf('application/pdf') === 0 ||
                        response.headers.get('content-type')
                            .indexOf('application/octet-stream') === 0 ?
                     response.blob() : response.text();
            })
            .then((data) => {
              if ( data === null ) {
                res({}); // throw new Error( 'null response' );
              } else {
                res(data);
              }
            })
            .catch((e) => {
              if ( typeof e.text == 'function' ) {
                e.text().then( (t)=>{
                  const errCode = this.generateErrorCode();
                  this.ajaxError( errCode, {}, {
                    error: t + ' ' + settings.method + ' ' +
                  (settings.bpoapi ?
                    this.BACHAPIURL : this.BACHURL) + settings.url,
                    source: '',
                    lineno: 1,
                    colno: 1,
                  });
                  rej(errCode);
                }).catch((e)=>{
                  rej(e);
                });
              } else {
                if ( e.name==='AbortError' || e.toString()
                    .indexOf( 'The operation was aborted.' ) > -1 ) {
                  // dont show error, when abort-controller was called
                  rej( e );
                } else {
                  const errCode = this.generateErrorCode();
                  this.ajaxError( errCode, {}, {
                    error: e.toString() + ' ' + settings.method + ' ' +
                  (settings.bpoapi ?
                    this.BACHAPIURL : this.BACHURL) + settings.url,
                    source: '',
                    lineno: 1,
                    colno: 1,
                  });
                  rej(errCode);
                }
              }
            });
      }
    });
  }

  ajaxError(code, error, errorInfo) {
    const datetime = new Date();
    console.log( `ERR#${code}`, datetime, errorInfo, error);
    console.html( errorInfo.error );
    const token = sessionStorage.getItem('bachtoken');
    fetch( this.BACHURL+this.buildApiUrl(URL_TYPES.LOG_ERROR), {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token ? 'Bearer ' + token : '',
      },
      body: new URLSearchParams({
        code,
        timestamp: datetime.getTime(),
        datetime: datetime.toISOString(),
        ...errorInfo,
      }),
    });
  }

  generateErrorCode() {
    return (Math.floor( Math.random()*90000 ) + 10000).toString();
  }

  validateFields(data) {
    let validation = true;
    for (const [key] of Object.entries(data)) {
      const field = document.querySelector(`#${key}`);
      if (key === 'text_de' || key === 'text_en') {
        if (field.value.trim().length > 1000) {
          validation = false;
          field.classList.add('is-invalid');
        }
      }
      if (key === 'tmg_reason') {
        if (field.value.trim() === '' || field.value.trim().length < 10) {
          validation = false;
          field.classList.add('is-invalid');
        }
      }
      if (!(key === 'text_de') && !(key === 'text_en') && field.value.trim() === '') {
        validation = false;
        field.classList.add('is-invalid');
      }
      const repeatField = field.getAttribute('data-repeat');
      if (repeatField) {
        const field2 = document.querySelector(`#${repeatField}`);
        if (field.value !== field2.value) {
          validation = false;
          field.classList.add('is-invalid');
          field2.classList.add('is-invalid');
        }
      }
      const dateAfterField = field.getAttribute('data-date-after');
      if (dateAfterField) {
        const field2 = document.querySelector(`#${dateAfterField}`);
        if (field.value <= field2.value) {
          validation = false;
          field.classList.add('is-invalid');
        }
      }
    }
    return validation;
  }
  sendData(fields) {
    const data = {};
    console.log(fields);
    fields.forEach((field) => {
      for (const [key, value] of Object.entries(field)) {
        if (key === 'error') continue;
        data[key] = value;
      }
    });
    return data;
  }
  addErrorMessage(text, setMethod, callback, timer = 3000) {
    setMethod([{variant: 'error', text, show: true}]);
    if (timer > 0) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        callback(0);
      }, timer);
    }
  }
  saveTempData(key, value) {
    sessionStorage.setItem('bach_temp_data_' + key, value);
  }
  getTempData(key) {
    return sessionStorage.getItem('bach_temp_data_' + key);
  }
  clearTempData(key) {
    sessionStorage.removeItem('bach_temp_data_' + key);
  }
  checkTokenExpired() {
    const token = sessionStorage.getItem('bachtoken');
    if (token) {
      // console.log( token );
      let tokenExpiration = Date.now() / 1000 - 1000;
      try {
        tokenExpiration = jwtDecode(token).exp;
      } catch (e) {
        console.log('invalid token, decode error');
        sessionStorage.removeItem('bachtoken');
      }
      // console.log( tokenExpiration );
      const dateNow = new Date();
      if (tokenExpiration < dateNow.getTime() / 1000) {
        // console.log( 'token expired');
        return false;
      } else {
        // console.log( 'token ok');
        return true;
      }
    } else {
      // console.log( 'no token in sessionStorage' );
      return false;
    }
  }
  getTokenInfo(token) {
    token = token || sessionStorage.getItem('bachtoken');
    if (token) {
      return jwtDecode(token);
    } else {
      return {};
    }
  }
  fixUserNameWidth(wrapperW) {
    // console.log(document.querySelector('#logo_bach'));
    const username = document.querySelector('#logo_bach + div > .truncate');
    username.style.maxWidth = (wrapperW - 240) + 'px';
  }
  getWeek(date) {
    if (!(date instanceof Date)) date = new Date();

    // ISO week date weeks start on Monday, so correct the day number
    const nDay = (date.getDay() + 6) % 7;


    // ISO 8601 states that week 1 is the week
    // with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);

    // Store the millisecond value of the target date
    const n1stThursday = date.valueOf();

    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);

    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
      date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }

    // The week number is the number of weeks
    // between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
  }

  getMondayOfCurrentWeek(date) {
    const today = new Date(date) || new Date();
    const weekday = today.getDay() === 0 ?
     7 : today.getDay(); // Sunday is 7 not 0 !!
    const first = today.getDate() - weekday + 1;
    // today.setDate(first)
    const monday = new Date(Date.UTC(today.getFullYear(),
        today.getMonth(),
        first));
    return monday.getTime();
  }

  printWeek(monday, currentDay) {
    if (currentDay) {
      currentDay = new Date(currentDay);
    } else {
      currentDay = new Date(monday);
    }

    const cells = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const tempDate = new Date(monday);
      tempDate.setDate(tempDate.getDate() + i);

      let day = i;
      if (tempDate.getDate() === today.getDate() &&
        tempDate.getMonth() === today.getMonth() &&
        tempDate.getFullYear() === today.getFullYear()
      ) {
        day = 7;
      }

      const active = (tempDate.getDate() === currentDay.getDate() &&
        tempDate.getMonth() === currentDay.getMonth() &&
        tempDate.getFullYear() === currentDay.getFullYear()
      );

      cells.push({
        day,
        nr: tempDate.getDate(),
        date: tempDate.getTime(),
        active,
      });
    }
    return cells;
  };

  printMonth(date, currentDay) {
    if (currentDay) {
      currentDay = new Date(currentDay);
    } else {
      currentDay = new Date(date);
    }
    const rows = [];
    do {
      const cells = [];
      // const today = new Date(selectedDay);
      for (let i = 0; i < 7; i++) {
        const tempDate = new Date(date);
        tempDate.setDate(tempDate.getDate() + i);
        const day = 0;

        /* if (tempDate.getDate() === 31) {
          console.log('31', tempDate, currentDay);
        }*/

        const active = (tempDate.getDate() === currentDay.getDate() &&
          tempDate.getMonth() === currentDay.getMonth() &&
          tempDate.getFullYear() === currentDay.getFullYear()
        );

        cells.push({
          day,
          nr: tempDate.getDate(),
          date: tempDate.getTime(),
          active,
        });
      }
      rows.push(cells);

      date = new Date(date);
      date.setDate(date.getDate() + 7);
      date = date.getTime();
    } while (rows.length < 6);
    return rows;
  };

  pad(a, b) {
    return (1e15 + a + '').slice(-b);
  }

  getTimeString(date) {
    // iOS fix
    date = date.replace(' ', 'T');
    date = new Date(date);
    return `${date.getHours()}:${this.pad(date.getMinutes(), 2)}`;
  }

  printCurrentDayString(setday, textWeekdays=[]) {
    // iOS fix
    if ( typeof setday === 'string' ) {
      setday = setday.replace(' ', 'T');
    }
    const currentDay = new Date(setday);
    // Sunday fix
    let day = currentDay.getDay() - 1;
    if (day === -1) {
      day = 6;
    };

    // eslint-disable-next-line max-len
    if ( textWeekdays.length === 0 ) {
      return `${currentDay.getDate()}.${currentDay.getMonth() + 1}.${currentDay.getFullYear()}`;
    } else {
      return `${textWeekdays[day]}, ${currentDay.getDate()}.${currentDay.getMonth() + 1}.${currentDay.getFullYear()}`;
    }
  }

  isSunday(date) {
    date = new Date(date);
    return date.getDay() == 0;
  }
  /* funktioniert, ist aber langsamer
  checkDayHas_slow(type, date, scheduleDates) {
    let found = false;
    // fix for timezone issue
    date = (new Date((new Date(date)).toDateString()));
    date = `${date.getFullYear()}-${this.pad((date.getMonth()+1), 2)}-${this.pad((date.getDate()), 2)}`;
    for ( let i=0; i<scheduleDates.length; i++ ) {
      if (type !== scheduleDates[i].event_type) continue;
      const startdate = scheduleDates[i].start.split(' ')[0];
      const enddate = scheduleDates[i].end.split(' ')[0];
      if (date >= startdate &&
        date <= enddate) {
        found = true;
        break;
      }
    }
    return found;
  }
  */

  printDateYMD(date ) {
    const d = new Date(date);
    return `${d.getFullYear()}-${this.pad(d.getMonth()+1, 2)}-${this.pad(d.getDate(), 2)}`;
  }

  checkDayHas(type, date, scheduleDates) {
    let found = false;
    date = Math.round( date / (3600*1000*24) );
    scheduleDates.forEach((event) => {
      if (found || type !== event.event_type) return false;
      const eventStartDay = Math.floor( event.unixepoch_start_time / (3600*24) );
      const eventEndDay = Math.floor( event.unixepoch_end_time/ (3600*24) );
      if (date >= eventStartDay &&
      date <= eventEndDay ) {
        found = true;
      }
    });
    return found;
  }

  randint(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  buildApiUrl(urlType, ...params) {
    params = params[0];
    switch (urlType) {
      case URL_TYPES.COURSE_SEARCH:
        return `/courses/search?q=${params.text}&s=${params.semester}`;
      case URL_TYPES.COURSE_DETAILS:
        return `/courses/info/${params.id}/semester/${params.semester}`;
      case URL_TYPES.COURSE_INSTRUCTORS:
        return `/courses/instructor/${params.id}/semester/${params.semester}`;
      case URL_TYPES.COURSE_SUBJECTS:
        return `/courses/planpunkt/${params.id}/semester/${params.semester}`;
      case URL_TYPES.EVENTS_MONTH:
        return `/member/events`;
      case URL_TYPES.EVENTS_WEEK:
        return `/member/events`;
      case URL_TYPES.EXAMS:
        return '/member/exams' +
        (params && params.semester &&
            typeof(params.semester) !== 'undefined' && params.semester !== -1 ?
            `?semester=${params.semester}` : '');
      case URL_TYPES.GRADES:
        return '/member/grades' +
        ((params &&
          params.study &&
          typeof(params.study) !== 'undefined' &&
          params.study !== -1) ? `?study=${params.study}` : '');
      case URL_TYPES.MY_COURSES:
        return `/member/courses/${params.semester}`+
            ((params &&
              params.study &&
              typeof(params.study) !== 'undefined' &&
              params.study !== -1) ? `?study=${params.study}` : '');
      case URL_TYPES.TILES:
        return '/member/tiles';
      case URL_TYPES.UPLOAD_AVATAR:
        return '/member/upload-avatar';
      case URL_TYPES.DELETE_AVATAR:
        return '/member/delete-avatar';
      case URL_TYPES.USER_INFO:
        return '/member/user-info';
      case URL_TYPES.USER_PROFILE:
        return '/member/user-profile';
      case URL_TYPES.DELIVERY_ADDRESS:
        return '/member/delivery-address';
      case URL_TYPES.THEME:
        return '/member/theme';
      case URL_TYPES.SEMESTERS:
        return '/util/semesters';
      case URL_TYPES.COUNTRIES:
        return '/util/countries';
      case URL_TYPES.LOG_ERROR:
        return '/bachapi/log-error';
      case URL_TYPES.ACCOUNT_PASSWORD:
        return '/account/change-account-pw';
      case URL_TYPES.WLAN_PASSWORD:
        return '/account/change-wlan-pw';
      case URL_TYPES.OFFICE365_CONFIG:
        return '/account/office365-config';
      case URL_TYPES.ACCEPT_POLICY:
        return '/account/accept-policy';
      case URL_TYPES.RESET_PW_SEND_CODE:
        return '/accountreset/reset-pw-send-code';
      case URL_TYPES.VERIFY_PW_RESET:
        return '/accountreset/verify-pw-reset';
      case URL_TYPES.NOTIFICATIONS:
        return '/member/notifications';
      case URL_TYPES.NOTIFICATION:
        // set notification read
        return '/member/notification';
      case URL_TYPES.ADMIN_NOTIFICATIONS:
        return '/notify-admin/notification';
      case URL_TYPES.NOTIFICATION_TYPE:
        return '/util/notification-type';
      case URL_TYPES.NOTIFICATION_GROUP:
        return '/notify-admin/notification-group';
      case URL_TYPES.STUDY_PROGRAMS:
        return '/member/study-programs';
      case URL_TYPES.MIN_REQUIREMENT:
        return '/member/min-requirement' +
            ((params && params.code &&
              typeof(params.code) !== 'undefined' && params.code !== -1) ?
              `?q=${params.code}` : '');
      case URL_TYPES.MIN_REQUIREMENT_EXEMPTION:
        return '/member/min-requirement-exemption';
      case URL_TYPES.USER_MAPPING:
        return '/mapping-admin/user-mapping';
      case URL_TYPES.THESIS_MANAGEMENT:
        return '/member/theses';
      case URL_TYPES.GET_SAVED_COURSES:
        return `/member/saved-courses/${params.study}`;
      case URL_TYPES.SAVE_COURSE:
        return '/member/save-course';
      case URL_TYPES.DELETE_COURSE:
        return '/member/delete-course';
      default:
        return '';
    }
  }

  buildUIRouteUrl(urlType, ...params) {
    params = params[0];
    switch (urlType) {
      case URL_TYPES.COURSE_DETAILS:
        return `/course/${params.id}/semester/${params.semester}`;
      case URL_TYPES.COURSE_INSTRUCTORS:
        return `/instructor/${params.id}/semester/${params.semester}`;
      case URL_TYPES.COURSE_SUBJECTS:
        return `/planpunkt/${params.id}/semester/${params.semester}`;
      case URL_TYPES.THESIS_DETAILS:
        return `/member/theses/${params.id}`;
      default:
        return '';
    }
  }

  htmlSanitized(html) {
    const config = {
      FORBID_TAGS: ['style'],
      FORBID_ATTR: ['style'],
      ADD_ATTR: ['target'],
    };
    const san = DOMPurify.sanitize(html, config);
    return reactHtmlParser(san);
  }


  hasPermission( key, userInfo = {}) {
    switch ( key ) {
      case 'notifications':
        return true;
        break;
      case 'notification-admin':
        return userInfo.roles && userInfo.roles[key] === 'Y';
        break;
      default:
        return userInfo.roles && userInfo.roles[key] === 'Y';
    }
  }


  checkCollision(eventOneStart, eventOneEnd, eventTwoStart, eventTwoEnd) {
    return eventOneStart < eventTwoEnd && eventOneEnd > eventTwoStart;
  };


  getLVPlanerCoursesForToday(date, coursesDB) {
    const eventsOnThisDay = [];
    let hasCollision = false;

    if (coursesDB.length > 0) {
      coursesDB.forEach((course) => {
        course.events.forEach((event) => {
          const savedStart = new Date(event.beginn);
          const savedEnd = new Date(event.ende);
          const currentDate = new Date(date);

          if (savedStart.toDateString() === currentDate.toDateString()) {
            eventsOnThisDay.forEach((existingEvent) => {
              const eventsOnThisDayStart = existingEvent.rawStartTime;
              const eventsOnThisDayEnd = existingEvent.rawEndTime;

              if (BA.checkCollision(savedStart, savedEnd, eventsOnThisDayStart, eventsOnThisDayEnd)) {
                hasCollision = true;
              }
            });

            eventsOnThisDay.push({
              lv_nr: course.lv_nr,
              titel: course.titel,
              titel_en: course.titel_en,
              startTime: BA.getTimeString(event.beginn),
              rawStartTime: new Date(event.beginn),
              endTime: BA.getTimeString(event.ende),
              rawEndTime: new Date(event.ende),
              semester: course.semester,
              raum: event.raum,
              // raum_en: event.raume_en,
            });

            eventsOnThisDay.sort((a, b) => a.rawStartTime - b.rawStartTime);
          }
        });
      });
    }

    return {eventsOnThisDay, hasCollision};
  };
}

/* const patterns = {
  username: /^[a-z\d]{5,12}$/i,
  password: /^[\d\w@-]{8,20}$/i,
  email: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
};*/

export const BA = new BachHelpers();
export {useTranslation};

/* eslint-disable max-len */
import '@member/pages/ThesisDetails.css';
import UI from '@ui/UI';
import {Row, Col, Card} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useTranslation} from '@util/bachhelpers.js';

function TableThesisHistory(props) {
  const {_} = useTranslation();
  const thesis = useSelector((state) => state.thesis);

  const historyColumns = [
    {
      id: 'version',
      selector: (row) => row.version,
      name: _('app.thesis.version'),
      wrap: true,
      minWidth: '70px',
      maxWidth: '70px',
    },
    {
      id: 'titleOrig',
      selector: (row) => String(row.title_original),
      name: _('app.thesis.title_orig'),
      wrap: true,
      minWidth: '20%',
    },
    {
      id: 'submittedAt',
      selector: (row) => row.submitted_at ? new Date(row.submitted_at).toLocaleString('de-DE', {dateStyle: 'medium'}) : _('app.thesis.not_available'),
      name: _('app.thesis.submitted_at'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '120px',
    },
    {
      id: 'archivedAt',
      selector: (row) => row.archived_at ? new Date(row.archived_at).toLocaleString('de-DE', {dateStyle: 'medium'}) : _('app.thesis.not_available'),
      name: _('app.thesis.archived_at'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '120px',
    },
    {
      id: 'archivedReason',
      selector: (row) => row.archived_reason ? String(row.archived_reason) : _('app.thesis.not_available'),
      name: _('app.thesis.archived_reason'),
      wrap: true,
      minWidth: '20%',
    },
  ];

  return (
    <>
      <Row className="my-2">
        <Col xxl={9}>
          <Card>
            <Card.Header as='h5'>{_('app.thesis.history')}</Card.Header>
            <Card.Body className='pt-0 px-3 pb-3'>
              <div className="theses-table">
                <UI.BachDataTable
                  noDataComponent={_('app.thesis.filter_no_result')}
                  hideCount={true}
                  hideFilter={true}
                  columns={historyColumns}
                  data={thesis.drafts?.sort((a, b) => {
                    if (a.version < b.version) {
                      return -1;
                    }
                    if (a.version > b.version) {
                      return 1;
                    }
                    return 0;
                  })}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TableThesisHistory;


import UI from '@ui/UI';
import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {BA} from '@util/bachhelpers.js';
// import Cookies from 'js-cookie';

function Login(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();


  /**
     * check if token queryparam is set and valid
     */
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect( ()=>{
    const forwardToMember = async ()=> {
      // const token = Cookies.get( 'bach-jwt' );
      // console.log( 'cookie token', token );
      const query = new URLSearchParams(searchParams);
      const code = query.get('code');
      const sessionState = query.get('session_state');

      const urlToken = query.get('token');

      if ( urlToken ) {
        sessionStorage.setItem( 'bachtoken', urlToken );
        dispatch({
          type: 'set_auth',
        });
        navigate('/member', {replace: true});
      }

      if ( code && sessionState ) {
        BA.ajax({
          url: `/idpapi/get-token`,
          method: 'post',
          data: {code, 'session_state': sessionState},
          bpoapi: false,
        }).then( (token)=>{
          if ( token && token !== '' ) {
            const mywuApp = sessionStorage.getItem( 'mywu_app' );
            if ( mywuApp === '1' ) {
              top.location.href = 'mywuapp://home/'+token;
            } else {
              sessionStorage.setItem( 'bachtoken', token );
              dispatch({
                type: 'set_auth',
              });
              navigate('/member', {replace: true});
            }
          } else {
            navigate('/', {replace: true});
          }
        });
      } else {
        navigate('/', {replace: true});
      }
    };
    forwardToMember();
  }, []);


  return (
    <div className="col-lg-4 col-xxl-3 ">
      <UI.AjaxLoading />
    </div>

  );
}

export default Login;

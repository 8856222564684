/* eslint-disable max-len */

import './ThesisManagement.css';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import {Row, Col, Form, Card, Tooltip, OverlayTrigger, Badge} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts.js';

function ThesisManagement(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  // useState
  const [xhrLoad, setXhrload] = useState(true);
  const [filterObj, setFilterObj] = useState({});
  const [filteredTheses, setFilteredTheses] = useState([]);
  const [toasts, setToasts] = useState([]);
  // useSelector
  const theses = useSelector((state) => state.theses);
  const tmgUserInfo = useSelector((state) => state.tmgUserInfo);
  const role = useSelector((state) => state.tmgCurrentRole);
  const language = useSelector((state) => state.language);

  // -------- initializing / useEffect --------

  /* fetch tmg user info if not already available in Redux store */
  useEffect(() => {
    if (!tmgUserInfo || Object.keys(tmgUserInfo).length === 0) {
      getTmgUserInfo();
    }
  }, [tmgUserInfo]);

  /* fetch theses once role is available */
  useEffect(() => {
    if (role) getTheses(role);
  }, [role]);

  /* process previous filter when theses or role changes */
  useEffect(()=> {
    if (theses.length > 0 && role) processPreviousFilter();
  }, [theses, role]);

  /* delete toasts after given time period */
  useEffect(() => {
    const visibleToasts = toasts.filter((toast) => toast.show);
    if (visibleToasts.length > 0) {
      setTimeout(() => {
        const updatedToasts = toasts.map((toast, ix) => {
          toast.show = false;
          return toast;
        });
        setToasts(updatedToasts);
      }, 7000);
    }
  }, [toasts]);

  // -------- API calls --------

  /* fetch user info regarding thesis management application */
  const getTmgUserInfo = () => {
    BA.ajax({
      url: '/auth/userinfo',
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setXhrload(false);
      } else if (response.data) {
        const tmgUserInfo = response.data;
        if (tmgUserInfo.roles?.length > 0 ) {
          dispatch({
            type: 'set_tmg_user_info',
            tmgUserInfo: tmgUserInfo,
          });
          dispatch({
            type: 'set_tmg_current_role',
            tmgCurrentRole: tmgUserInfo.roles[0],
          });
        }
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
    });
  };

  /* fetch theses for current role */
  const getTheses = () => {
    setXhrload(true);
    BA.ajax({
      url: `/summary/${role}`,
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
        setXhrload(false);
      } else if (response.data) {
        const theses = response.data;
        if (theses.length > 0) {
          // prepare theses for dispatch
          theses.forEach((thesis) => {
            thesis.student_matricle = String(thesis.student_matricle).padStart(8, '0');
            thesis.thesisState = thesis.state.tid;
            thesis.blockingState = thesis.blocking_state.tid;
            thesis.similarityState = thesis.fk_similarity_state;
            thesis.isStudyAborted = thesis.zulassungscode === 'Z';
            thesis.isApproved = thesis.state.tid >= 6 ? 1 : 2;
            thesis.thesisType = thesis.type.tid;
          });
          dispatch({
            type: 'set_theses',
            theses: theses,
          });
          setFilteredTheses(theses);
        // no theses found: dispatch empty array
        } else {
          dispatch({
            type: 'set_theses',
            theses: [],
          });
          setFilteredTheses([]);
        }
        setXhrload(false);
      }
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
    });
  };

  // -------- Helper functions --------

  /* close toast with error message */
  const handleClose = (i) => {
    const updatedToasts = toasts.map((toast, ix)=>{
      if (i === ix) {
        toast.show = false;
      }
      return toast;
    });
    setToasts(updatedToasts);
  };

  /* get columns for current role */
  const getColumnsByRole = () => {
    switch (role) {
      case 'admin':
        return columnsAdmin;
      case 'teacher':
        return columnsTeacher;
      case 'program_manager':
        return columnsManager;
      case 'student':
        return columnsStudent;
      default:
        return [];
    }
  };

  /* process previous filter:
     - get previous filter from storage
     if previous filter:
     - delete stored filter from another role
     - process stored filter from same role with values
     no previous filter:
     - reset filter
  */
  const processPreviousFilter = () => {
    // get previous filter from storage
    if (sessionStorage.getItem('storedFilter')) {
      const storedFilter = JSON.parse(sessionStorage.getItem('storedFilter'));
      // delete stored filter from another role that is not in user roles
      if (!tmgUserInfo?.roles?.includes(storedFilter.role)) {
        sessionStorage.removeItem('storedFilter');
        setFilterObj({});
        setFilteredTheses(theses);
      // process stored filter from same role with values
      } else if (storedFilter.role === role && Object.keys(storedFilter.filterObj).length > 0) {
        setFilterObj(storedFilter.filterObj);
        setFilteredTheses(filterTheses(storedFilter.filterObj));
      }
    // no previous filter in storage
    } else {
      setFilteredTheses(theses);
    }
  };

  /* filter results
     - depending on target.id (selected option)
       - if all (-1) is selected delete attribut in filterObj
       - else set attribut in filterObj
     - set current filter in session storage
     - set new filterObj
     - filter theses and set as filteredTheses
  */
  const filterResults = (target) => {
    const newFilterObj = {...filterObj};
    const val = parseInt(target.value);
    switch (target.id) {
      case 'select-thesis-type':
        if (val === -1 && newFilterObj.hasOwnProperty('thesisType')) delete newFilterObj.thesisType;
        else if (val !== -1) newFilterObj.thesisType = val;
        break;
      case 'select-thesis-state':
        if (val === -1 && newFilterObj.hasOwnProperty('thesisState')) delete newFilterObj.thesisState;
        else if (val !== -1) newFilterObj.thesisState = val;
        break;
      case 'select-similarity-state':
        if (val === -1 && newFilterObj.hasOwnProperty('similarityState')) delete newFilterObj.similarityState;
        else if (val !== -1) newFilterObj.similarityState = val;
        break;
      case 'select-blocking-state':
        if (val === -1 && newFilterObj.hasOwnProperty('blockingState')) delete newFilterObj.blockingState;
        else if (val !== -1) newFilterObj.blockingState = val;
        break;
      case 'switch-aborted-studies':
        // if target.checked = true... delete
        if ((target.checked) && newFilterObj.hasOwnProperty('isStudyAborted')) delete newFilterObj.isStudyAborted;
        // target.checked = false... set
        else newFilterObj.isStudyAborted = false;
        break;
      case 'select-approved':
        if ((val === -1) && newFilterObj.hasOwnProperty('isApproved')) delete newFilterObj.isApproved;
        else if (val !== -1) newFilterObj.isApproved = val;
        break;
      default:
        break;
    }

    const storedFilter = {
      role: role,
      filterObj: {...newFilterObj},
    };

    sessionStorage.setItem('storedFilter', JSON.stringify(storedFilter));
    setFilterObj(newFilterObj);
    setFilteredTheses(filterTheses(newFilterObj));
  };

  /* filter theses
     - loop through theses
       - for each key in filterObj
        - check if thesis fulfills criteria of key/value in filterObj
          e.g. filterObj = {"thesisState": 1}
        - only theses passing all criteria make it to filtered */
  const filterTheses = (filterObj) => {
    const filtered = theses.filter((t) => {
      for (const key in filterObj) {
        if (t[key] === undefined || t[key] !== filterObj[key]) {
          return false;
        }
      }
      return true;
    });
    return filtered;
  };

  /* handle change of select elements  */
  const handleChange = (event) => {
    filterResults(event.target);
  };

  /* handle change of role */
  const handleRoleChange = (event) => {
    dispatch({
      type: 'set_tmg_current_role',
      tmgCurrentRole: event.target.id,
    });
  };

  /* calculate due days of grade */
  const calculateGradeDueDays = (submittedAt) => {
    if (!submittedAt) return 0;
    const submitted = new Date(submittedAt).getTime();
    return Math.round((new Date().getTime() - submitted) / (1000 * 60 * 60 * 24));
  };

  // -------- Columns for DataTable --------

  /* columns for data table admin */
  const columnsAdmin = [
    {
      id: 'mnr',
      selector: (row) => row.student_matricle,
      name: _('app.thesis.student_id'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '100px',
    },
    {
      id: 'lastName',
      selector: (row) => row.student_surname,
      name: _('app.thesis.last_name'),
      wrap: true,
      sortable: true,
      minWidth: '70px',
      maxWidth: '100px',
    },
    {
      id: 'firstName',
      selector: (row) => row.student_firstname,
      name: _('app.thesis.first_name'),
      wrap: true,
      minWidth: '70px',
      maxWidth: '100px',
    },
    {
      id: 'titleOrig',
      selector: (row) => String(row.title_original),
      name: _('app.thesis.title_orig'),
      wrap: true,
      minWidth: '10%',
      cell: (row) => (<>
        { row.title_original ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_original}
              </Tooltip>
            }
          >
            <li className='tmg-col-admin'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_original}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'titleEn',
      selector: (row) => String(row.title_english),
      name: _('app.thesis.title_en'),
      wrap: true,
      minWidth: '10%',
      cell: (row) => (<>
        { row.title_english ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_english}
              </Tooltip>
            }
          >
            <li className='tmg-col-admin'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_english}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'type',
      selector: (row) => _(`app.thesis.types.${row.type.tid}.short`),
      name: _('app.thesis.type'),
      wrap: true,
      sortable: true,
      minWidth: '40px',
      maxWidth: '60px',
    },
    {
      id: 'isBlocked',
      selector: (row) => row.is_blocking ? _('app.thesis.blocking_yes') : _('app.thesis.blocking_no'),
      name: _('app.thesis.blocking'),
      wrap: true,
      sortable: true,
      minWidth: '40px',
      maxWidth: '60px',
    },
    {
      id: 'stateSubmission',
      selector: (row) => <div>
        <span>{_(`app.thesis.thesis_states.${row.state.tid}.key`)}</span>
        {row.draft_versions > 1 &&
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {_(`app.thesis.version`)} {row.draft_versions}
              </Tooltip>
            }
          >
            <Badge pill>{row.draft_versions}</Badge>
          </OverlayTrigger>
        }
      </div>,
      name: `${_('app.thesis.state')}`,
      wrap: true,
      sortable: true,
      minWidth: '80px',
      maxWidth: '170px',
    },
    {
      id: 'submittedAt',
      selector: (row) => row.submitted_at ? new Date(row.submitted_at).toLocaleString('de-DE', {dateStyle: 'medium'}) : 'n/a',
      name: _('app.thesis.submitted'),
      wrap: true,
      sortable: true,
      minWidth: '90px',
      maxWidth: '100px',
    },
    {
      id: 'since',
      selector: (row) => row.state.tid < 7 ? calculateGradeDueDays(row.submitted_at) : _('app.thesis.graded'),
      name: _('app.thesis.since'),
      wrap: true,
      sortable: true,
      minWidth: '70px',
      maxWidth: '80px',
    },
  ];

  /* columns for data table program manager */
  const columnsManager = [
    {
      id: 'mnr',
      selector: (row) => row.student_matricle,
      name: _('app.thesis.student_id'),
      wrap: true,
      minWidth: '70px',
      maxWidth: '100px',
    },
    {
      id: 'lastName',
      selector: (row) => row.student_surname,
      name: _('app.thesis.last_name'),
      wrap: true,
      sortable: true,
      minWidth: '80px',
      maxWidth: '100px',
    },
    {
      id: 'firstName',
      selector: (row) => row.student_firstname,
      name: _('app.thesis.first_name'),
      wrap: true,
      sortable: true,
      minWidth: '80px',
      maxWidth: '100px',
    },
    {
      id: 'titleOrig',
      selector: (row) => String(row.title_original),
      name: _('app.thesis.title_orig'),
      wrap: true,
      minWidth: '15%',
      cell: (row) => (<>
        { row.title_original ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_original}
              </Tooltip>
            }
          >
            <li className='tmg-col-manager'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_original}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'titleEn',
      selector: (row) => String(row.title_english),
      name: _('app.thesis.title_en'),
      wrap: true,
      minWidth: '15%',
      cell: (row) => (<>
        { row.title_english ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_english}
              </Tooltip>
            }
          >
            <li className='tmg-col-manager'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_english}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'type',
      selector: (row) => _(`app.thesis.types.${row.type.tid}.short`),
      name: _('app.thesis.type'),
      wrap: true,
      sortable: true,
      minWidth: '50px',
      maxWidth: '70px',
    },
    {
      id: 'stateSubmission',
      selector: (row) => _(`app.thesis.thesis_states.${row.state.tid}.key`),
      name: `${_('app.thesis.state')}`,
      wrap: true,
      sortable: true,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      id: 'stateBlocking',
      selector: (row) => _(`app.thesis.blocking_states.${row.blocking_state.tid}.key`),
      name: `${_('app.thesis.state')} ${_('app.thesis.blocking')}`,
      wrap: true,
      sortable: true,
      minWidth: '80px',
      maxWidth: '170px',
    },
  ];

  /* columns for data table teacher */
  const columnsTeacher = [
    {
      id: 'mnr',
      selector: (row) => row.student_matricle,
      name: _('app.thesis.student_id'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '100px',
    },
    {
      id: 'lastName',
      selector: (row) => row.student_surname,
      name: _('app.thesis.last_name'),
      wrap: true,
      sortable: true,
      minWidth: '80px',
      maxWidth: '100px',
    },
    {
      id: 'firstName',
      selector: (row) => row.student_firstname,
      name: _('app.thesis.first_name'),
      wrap: true,
      minWidth: '80px',
      maxWidth: '100px',
    },
    {
      id: 'titleOrig',
      selector: (row) => String(row.title_original),
      name: _('app.thesis.title_orig'),
      wrap: true,
      minWidth: '15%',
      cell: (row) => (<>
        { row.title_original ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_original}
              </Tooltip>
            }
          >
            <li className='tmg-col-teacher'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_original}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'titleEn',
      selector: (row) => String(row.title_english),
      name: _('app.thesis.title_en'),
      wrap: true,
      minWidth: '15%',
      cell: (row) => (<>
        { row.title_english ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_english}
              </Tooltip>
            }
          >
            <li className='tmg-col-teacher'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_english}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'type',
      selector: (row) => _(`app.thesis.types.${row.type.tid}.short`),
      name: _('app.thesis.type'),
      wrap: true,
      sortable: true,
      minWidth: '45px',
      maxWidth: '80px',
    },
    {
      id: 'stateSubmission',
      selector: (row) => <div>
        <span>{_(`app.thesis.thesis_states.${row.state.tid}.key`)}</span>
        {row.draft_versions > 1 &&
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {_(`app.thesis.version`)} {row.draft_versions}
              </Tooltip>
            }
          >
            <Badge pill>{row.draft_versions}</Badge>
          </OverlayTrigger>
        }
      </div>,
      name: `${_('app.thesis.state')}`,
      wrap: true,
      sortable: true,
      minWidth: '90px',
      maxWidth: '170px',
    },
    {
      id: 'simReport',
      selector: (row) => row.fk_similarity_state >= 7 ? 'ja' : 'nein',
      name: _('app.thesis.similarity_report'),
      wrap: true,
      sortable: true,
      minWidth: '50px',
      maxWidth: '80px',
    },
    {
      id: 'simScoring',
      selector: (row) => row.similarity_scoring ? row.similarity_scoring + ' %' : 'n/a',
      name: _('app.thesis.similarity'),
      wrap: true,
      sortable: true,
      minWidth: '50px',
      maxWidth: '80px',
    },
  ];

  /* columns for data table student */
  const columnsStudent = [
    {
      id: 'titleOrig',
      selector: (row) => String(row.title_original),
      name: _('app.thesis.title_orig'),
      wrap: true,
      minWidth: '20%',
      cell: (row) => (<>
        { row.title_original ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_original}
              </Tooltip>
            }
          >
            <li className='tmg-col-student'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_original}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'titleEn',
      selector: (row) => String(row.title_english),
      name: _('app.thesis.title_en'),
      wrap: true,
      minWidth: '20%',
      cell: (row) => (<>
        { row.title_english ?
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {row.title_english}
              </Tooltip>
            }
          >
            <li className='tmg-col-student'>
              <UI.BachLink
                href={BA.buildUIRouteUrl(URL_TYPES.THESIS_DETAILS,
                    {id: row.thesis_id})}
              >
                {row.title_english}
              </UI.BachLink>
            </li>
          </OverlayTrigger>
        </> :
        <span>{_('app.thesis.not_available')}</span>
        }
      </>
      ),
    },
    {
      id: 'study',
      selector: (row) => language === 'de' ? row.degree_program_names['de'] : row.degree_program_names['en'],
      name: _('app.thesis.study'),
      wrap: true,
      sortable: true,
      minWidth: '20%',
    },
    {
      id: 'type',
      selector: (row) => _(`app.thesis.types.${row.type.tid}.long`),
      name: _('app.thesis.type'),
      wrap: true,
      sortable: true,
      minWidth: '100px',
      maxWidth: '170px',
    },
    {
      id: 'stateSubmission',
      selector: (row) => _(`app.thesis.thesis_states.${row.state.tid}.key`),
      name: `${_('app.thesis.state')}`,
      wrap: true,
      sortable: true,
      minWidth: '10px',
      maxWidth: '170px',
    },
  ];

  return (
    <Col className="p-0">
      <Row className="p-4 g-0">
        <div className="thesis-overview col-12 col-lg-10 offset-lg-1">
          <Row>
            <Col xs={12} md={6}>
              <UI.PageTitle backurl="/member">
                {_('app.thesis.title_overview') }
              </UI.PageTitle>
            </Col>
            <Col xs={12} md={6} className="d-flex flex-row justify-content-end">
              {!xhrLoad && tmgUserInfo?.roles?.length > 1 && tmgUserInfo.roles.map((r) =>
                <button
                  {... (r !== role && {className: 'btn btn-primary btn-role'})}
                  {... (r === role && {className: 'btn btn-secondary btn-role'})}
                  onClick={r !== role ? handleRoleChange : undefined}
                  key={'role-'+r}
                  id={r}
                >{_(`app.thesis.roles.${r}`)}
                </button>,
              )}
            </Col>
          </Row>
          <div className='toast-container'>
            <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
          </div>
          {xhrLoad && <UI.AjaxLoading />}
          {/* no roles */}
          {!xhrLoad && !role &&
            <Row>
              <Col lg={6}>
                <div className='mt-3'>
                  <UI.InfoBox>
                    <UI.InfoText>
                      {_('app.thesis.no_roles')}
                    </UI.InfoText>
                  </UI.InfoBox>
                </div>
              </Col>
            </Row>
          }
          {!xhrLoad && role && <>
            {/* no result */}
            {theses.length === 0 &&
            <Row>
              <Col lg={6}>
                <div className='mt-3'>
                  <UI.InfoBox>
                    <UI.InfoText>
                      {_('app.thesis.no_result')}
                    </UI.InfoText>
                  </UI.InfoBox>
                </div>
              </Col>
            </Row>
            }
            {theses.length > 0 && <>

              {/* Filter */}
              {role !== 'student' &&
                <Card className="mt-3">
                  <Card.Body>
                    {role === 'admin' && <>
                      <Row className='d-flex'>
                        <Col xs={12} sm={3}>
                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-type-state-label"
                              className="m-0">
                              {_('app.thesis.type')}
                            </span>
                          </div>
                          <Form.Select
                            id="select-thesis-type"
                            data-testid="all-thesis-types-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select thesis type type"
                            onChange={handleChange}
                            defaultValue={filterObj.thesisType || -1}>
                            <option data-testid="all-thesis-types-option"
                              className="option-semester"
                              key={'type-0'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>
                            { Array.from({length: 3}, (_, i) => i + 1).map((type) =>
                              <option data-testid={'thesis-type-option-'+type}
                                className="option-semester"
                                key={'thesis-type-'+type}
                                value={type}
                              >
                                {_(`app.thesis.types.${type}.long`) }
                              </option>,
                            )}
                          </Form.Select>
                        </Col>
                        <Col xs={12} sm={3}>
                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-thesis-state-label"
                              className="m-0">
                              {_('app.thesis.state')} {_('app.thesis.submission')}
                            </span>
                          </div>
                          <Form.Select
                            id="select-thesis-state"
                            data-testid="all-thesis-states-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select thesis states"
                            onChange={handleChange}
                            defaultValue={filterObj.thesisState || -1}>
                            <option data-testid="all-thesis-states-option"
                              className="option-semester"
                              key={'thesis-states-0'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>
                            { Array.from({length: 12}, (_, i) => i + 1).map((state) =>
                              <option data-testid={'thesis-states-option-'+state}
                                className="option-semester"
                                key={'thesis-states-'+state}
                                value={state}
                              >
                                {_(`app.thesis.thesis_states.${state}.key`) }
                              </option>,
                            )}
                          </Form.Select>
                        </Col>
                        <Col xs={12} sm={3}>
                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-similarity-state-label"
                              className="m-0">
                              { _('app.thesis.state')} { _('app.thesis.similarity_report')}
                            </span>
                          </div>
                          <Form.Select
                            id="select-similarity-state"
                            data-testid="all-similarity-states-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select similarity states"
                            onChange={handleChange}
                            defaultValue={filterObj.similarityState || -1}>
                            <option data-testid="all-similarity-states-option"
                              className="option-semester"
                              key={'similarity-states-0'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>

                            { [1, 7, 10].map((state)=>(
                              <option data-testid={'similarity-states-option-'+state}
                                className="option-semester"
                                key={'similarity-states-'+state}
                                value={state}
                              >
                                { _(`app.thesis.similarity_states.${state}.key`) }
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col xs={12} sm={3}>

                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-thesis-state-label"
                              className="m-0">
                              {_('app.thesis.state')} {_('app.thesis.blocking')}
                            </span>
                          </div>
                          <Form.Select
                            id="select-blocking-state"
                            data-testid="all-blocking-states-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select blocking states"
                            onChange={handleChange}
                            defaultValue={filterObj.blockingState || -1}>
                            <option data-testid="all-blocking-states-option"
                              className="option-semester"
                              key={'blocking-states-0'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>
                            { Array.from({length: 5}, (_, i) => i + 1).map((state)=>(
                              <option data-testid={'blocking-states-option-'+state}
                                className="option-semester"
                                key={'blocking-states-'+state}
                                value={state}
                              >
                                { _(`app.thesis.blocking_states.${state}.key`) }
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row className='d-flex mt-2'>
                        <Col>
                          <Form>
                            <Form.Check
                              type="switch"
                              id="switch-aborted-studies"
                              label="inkl. abgebrochene Studien"
                              onChange={handleChange}
                              defaultChecked={filterObj.hasOwnProperty('isStudyAborted') ? filterObj.isStudyAborted : true}
                            />
                          </Form>
                        </Col>
                      </Row>
                    </>}
                    {role === 'teacher' && <>
                      <Row className='d-flex'>
                        <Col xs={12} sm={4}>
                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-thesis-state-label"
                              className="m-0">Freigabe
                            </span>
                          </div>
                          <Form.Select
                            id="select-approved"
                            data-testid="all-theses-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select theses"
                            onChange={handleChange}
                            defaultValue={filterObj.isApproved || -1}>
                            <option data-testid="all-theses-option"
                              className="option-semester"
                              key={'thesis-all'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>
                            <option data-testid={'theses-approved'}
                              className="option-semester"
                              key={'theses-approved'}
                              value={1}
                            >
                              { _('app.thesis.approved') }
                            </option>
                            <option data-testid={'theses-not-approved'}
                              className="option-semester"
                              key={'theses-not-approved'}
                              value={2}
                            >
                              { _('app.thesis.not_approved') }
                            </option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </>}
                    {role === 'program_manager' && <>
                      <Row className='d-flex'>
                        <Col xs={12} sm={4}>
                          <div className="text-wrap justify-content-start">
                            <span
                              data-testid="current-thesis-state-label"
                              className="m-0">
                              {_('app.thesis.state')} {_('app.thesis.blocking')}
                            </span>
                          </div>
                          <Form.Select
                            id="select-blocking-state"
                            data-testid="all-blocking-states-selector"
                            className={`mt-2 mb-2 smallselect`}
                            aria-label="select blocking states"
                            onChange={handleChange}
                            defaultValue={filterObj.blockingState || 2}>
                            <option data-testid="all-blocking-states-option"
                              className="option-semester"
                              key={'blocking-states-0'}
                              value={-1}>
                              {_('app.thesis.all')}
                            </option>
                            { Array.from({length: 4}, (_, i) => i + 1).map((state)=>(
                              <option data-testid={'blocking-states-option-'+state}
                                className="option-semester"
                                key={'blocking-states-'+state+1}
                                value={state + 1}
                              >
                                { _(`app.thesis.blocking_states.${state + 1}.key`) }
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </>}
                  </Card.Body>
                </Card>
              }

              {/* Overview */}
              <div className="theses-table">
                <UI.BachDataTable
                  noDataComponent={_('app.thesis.filter_no_result')}
                  hideCount={role !== 'student' ? false : true}
                  hideFilter={role !== 'student' ? false : true}
                  columns={getColumnsByRole()}
                  data={filteredTheses}
                  defaultSortFieldId={'lastName'}
                  {... (role !== 'student' && {filterCols: ['student_matricle', 'student_surname', 'student_firstname']})}
                  {... (role !== 'student' && {placeholder: 'Suche nach Name/MNR'})}
                />
              </div>

            </>}
          </>}
        </div>
      </Row>
    </Col>
  );
}

export default ThesisManagement;

/* eslint-disable max-len */
import UI from '@ui/UI';
import {Col, Row} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useState, useEffect} from 'react';
import {URL_TYPES} from '@util/consts.js';


function Favorites(props) {
  const {_} = useTranslation();

  const tiles = useSelector((state) => state.tiles);
  const [isEditable, setIsEditable] = useState( true );

  const [xhrload, setXhrload] = useState(false);
  const dispatch = useDispatch();

  const [containerClasses, setContainerClasses] = useState( [
    'col-12', 'offset-lg-1', 'col-lg-4', 'col-xxl-3',
  ] );


  const handleChange = (e) => {
    setIsEditable(e.target.checked);
    setContainerClasses( e.target.checked ? [
      'col-12', 'offset-lg-1', 'col-lg-4', 'col-xxl-3',
    ]:[
      'col-12', 'offset-lg-1', 'col-lg-10',
    ] );
  };

  const updateTiles = (tiles) => {
    const changedTiles = tiles.map( (e, i)=>{
      return {
        fk_tile: e.fk_tile,
        // fk_profile: e.fk_profile,
        ranking: e.ranking,
        active: e.active,
      };
    });

    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.TILES),
      method: 'put',
      contentType: 'application/json',
      data: JSON.stringify({tiles: changedTiles}),
      bpoapi: true,
    }).then(async (response) => {
      console.log('fetch tiles update callback');
      setXhrload(false);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  useEffect(() => {
    if (!xhrload) {
      if (tiles.length === 0 /* && tiles.length===1 && !tiles[0].loaded*/) {
        setXhrload(true);
        /**
         * load tiles
         */
        BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.TILES),
          bpoapi: true,
        }).then(async (response) => {
          console.log('fetch tiles callback');
          setXhrload(false);
          if ( response.data && response.data.tiles ) {
            dispatch({
              type: 'set_tiles',
              tiles: response.data.tiles,
            });
          }
        });
      }
    }
  }, [tiles]);

  const editTile = (i, active) => {
    const newTiles = tiles.map((e)=>({...e}));
    newTiles[i].active = active;

    setXhrload(true);
    dispatch({
      type: 'set_tiles',
      tiles: newTiles,
    });
    updateTiles(newTiles);
  };

  const fixDOMOrder = ( max )=>{
    for ( let i = max-2; i>=0; i-- ) {
      const el = document.querySelector( '[data-index="'+i+'"]');
      const nextEl = document.querySelector( '[data-index="'+(i+1)+'"]');
      if ( nextEl && el && nextEl.parentNode ) {
        nextEl.parentNode.insertBefore(el, nextEl );
      }
    }
  };


  const saveTileOrder = ( changedTiles ) => {
    console.log( 'saveTileOrder', changedTiles );
    setXhrload(true);
    dispatch({
      type: 'set_tiles',
      tiles: changedTiles,
    });
    fixDOMOrder(changedTiles.length);
    updateTiles(changedTiles);
  };

  return (
    <Col className="p-0">

      <Row className="px-4 pt-4 g-0">
        <div className={containerClasses.join(' ')}>

          <UI.PageTitle backurl="/member">
            {_('app.favorites.title')}
          </UI.PageTitle>

          <div className="favorites p-4">
            {xhrload && <UI.AjaxLoading />}
            {!xhrload &&<UI.BachTileGroup
              gap="4"
              editable
              tiles={tiles}
              editTile={editTile}
              saveTileOrder={saveTileOrder}
              isEditable={isEditable}
              handleChange={handleChange}
            />}
          </div>
        </div>
      </Row>
    </Col>
  );
}
export default Favorites;

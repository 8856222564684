export default class Overscroll {
  constructor() {
    if (!document.querySelector('.pull-to-refresh') &&
    process.env.REACT_APP_MOBILEAPP ) {
      this.init();
      this.blockTargetBlank();
    }
  }
  init() {
    const pullToRefresh = document.createElement('div');
    pullToRefresh.className = 'pull-to-refresh';
    pullToRefresh.innerHTML = '<div class="spinner-border"></div>';
    document.querySelector('body').prepend(pullToRefresh);
    this.startTime = -1;
    let touchstartY = 0;
    document.addEventListener('touchstart', (e) => {
      touchstartY = e.touches[0].clientY;
      this.startTime = Date.now();
    });
    document.addEventListener('touchmove', (e) => {
      const touchY = e.touches[0].clientY;
      const touchDiff = touchY - touchstartY;
      if ( touchDiff > 0 && window.scrollY === 0) {
        pullToRefresh.classList.add('visible');
        e.preventDefault();
      }
    });
    document.addEventListener('touchend', () => {
      console.log( this.startTime, Date.now() - this.startTime);
      if ( Date.now() - this.startTime > 1000 ) {
        pullToRefresh.classList.remove('visible');
        location.reload();
      } else {
        pullToRefresh.classList.remove('visible');
      }
      this.startTime = -1;
    });
  }
  blockTargetBlank() {
    document.querySelector('main').addEventListener( 'click', (e)=>{
      if ( e.target.getAttribute('target') == '_blank' ) {
        e.target.setAttribute('target', '_self');
      }
    });
  }
}

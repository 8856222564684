import BachTile from './BachTile';
import InfoText from './InfoText';
import './BachTileGroup.css';
import {useSelector} from 'react-redux';
import {useState, useEffect} from 'react';

import {Form} from 'react-bootstrap';

import {useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';
import {editTile, adminTiles} from '@util/static_tiles';

import {DnD} from '@util/bach_dragndrop.js';

function BachTileGroup( props ) {
  const {_, i18n} = useTranslation();
  const classnames = ['featurebuttons', props.className].join(' ');
  const language = useSelector((state) => state.language);


  const newTiles=props.tiles.map((e)=>({...e}));
  const [myTiles, setMyTiles] = useState( newTiles );

  // is favoties editable
  // const [isEditable, setIsEditable] = useState( props.editable );
  // console.log( isEditable, setIsEditable );

  if ( props.editTile ) {
    useEffect(() => {
      setMyTiles( props.tiles.map((e)=>({...e})) );
      DnD.newTiles = newTiles;
      DnD.saveTileOrder = props.saveTileOrder;
      DnD.setMyTiles = ()=>{
        // new to be a new array to update everytime
        setMyTiles([...DnD.newTiles]);
      };
    }, [props.tiles]);

    useEffect(() => {
      console.log( DnD.dragIndex+1 );

      DnD.placeholder = document.querySelector(
          '[data-index="'+(DnD.dragIndex+1)+'"]',
      );
    }, [myTiles]);
  }
  /**
   * extra tile at end of all tiles, do open favorites page
   * @param {*} tiles
   * @returns
   */
  const addTiles = (tiles)=>{
    const newtiles = [...tiles];
    if ( props.showedittile ) {
      newtiles.push(...editTile);
    }
    if ( props.showadmintiles ) {
      newtiles.push(...adminTiles);
    }
    return newtiles;
  };

  return (
    <>
      {props.title&&<h2 className="pt-4">{props.title}</h2>}
      {props.editable && <><Form.Check
        id='editable_favorites'
        label={_('app.favorites.iseditable')}
        checked={props.isEditable}
        onChange={props.handleChange} /><br /></>}
      <nav className={classnames}>
        <ul className={`row g-${props.gap?props.gap:'2'}`}>
          {addTiles(myTiles).map( (tile, i)=>
           (props.editable||tile.active) ? <BachTile
             {...(!tile.placeholder && props.isEditable?{
               onDragStart: DnD.dragStartHandler,
             }:'')}
             key={`tile${i}`}
             title={
              tile.titlekey ? _( tile.titlekey ): (
              tile.title?
                tile.title:
                (language === LANGUAGES.EN? tile.title_en: tile.title_de)
                )}
             href={tile.hreftrans?
                tile.hreftrans.replace(/%language%/, i18n.language):
                tile.href}
             icon={tile.icon}
             variant={tile.variant? tile.variant:''}
             isactive={props.isEditable && tile.active?true:false}
             isEditable={props.isEditable}
             editable={props.editable}
             index={i}
           >
             {tile.info&&<InfoText>{
              tile.infokey?_(tile.infokey):tile.info
             }</InfoText>}
             {props.isEditable && !tile.placeholder? (tile.active?
              <button
                className="removeTile"
                onClick={()=>{
                  props.editTile(i, 0);
                }}
              >-</button>:
              <button
                className="addTile"
                onClick={()=>{
                  props.editTile(i, 1);
                }}
              >+</button>):''}
           </BachTile> : '')
          }
        </ul>
      </nav>

    </>
  );
}
BachTileGroup.propTypes={
  editable: PropTypes.bool,
  className: PropTypes.string,
  tiles: PropTypes.array,
  title: PropTypes.string,
  gap: PropTypes.string,
  showedittile: PropTypes.bool,
  showadmintiles: PropTypes.bool,
  editTile: PropTypes.func,
  saveTileOrder: PropTypes.func,
  isEditable: PropTypes.bool,
  handleChange: PropTypes.func,

};
export default BachTileGroup;
